import React from 'react';
import { Box, LinearProgress } from '@mui/material';
import { LOYALTY_APPLY_STATUS } from '../../reducers/cartSlice.constants';
import Colors from '../../utils/color';

interface Props {
  status: LOYALTY_APPLY_STATUS;
  error?: string;
}

export default function LoyaltyApplyStatus({ status, error }: Props) {
  if (status === LOYALTY_APPLY_STATUS.INVALID) {
    return (
      <Box
        data-testid="invalid-bottom"
        sx={{
          backgroundColor: Colors.torchRed,
          color: 'white',
          fontSize: '10px',
          letterSpacing: '1px',
          fontWeight: 500,
          p: '3px 10px',
        }}
      >
        {error || 'Invalid Coupon Number'}
      </Box>
    );
  }

  if (status === LOYALTY_APPLY_STATUS.PROCESSING) {
    return (
      <LinearProgress
        data-testid="processing-bottom"
        variant="determinate"
        value={66}
        sx={{
          backgroundColor: Colors.alto,
          '& .MuiLinearProgress-bar': {
            backgroundColor: Colors.silverChalice,
          },
        }}
      />
    );
  }

  if (status === LOYALTY_APPLY_STATUS.SUCCESS) {
    return (
      <LinearProgress
        data-testid="loyalty-coupon-success-progress-bar"
        variant="determinate"
        value={100}
        color="success"
      />
    );
  }

  return <></>;
}
