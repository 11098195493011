import React, { MouseEvent, useEffect } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  Typography,
  Theme,
} from '@mui/material';
import { LOYALTY_APPLY_STATUS } from '../../reducers/cartSlice.constants';
import LoyaltyApplyStatus from './LoyaltyApplyStatus';
import OrderProgressBar from './OrderProgressBar';
import { OrderStatus } from '../../reducers/orderSlice.constants';
import {
  messagingActions,
  InfoTransmissionMessage,
} from '../../reducers/messagingSlice';
import { useAppDispatch } from '../../app/hooks';
import { makeStyles } from '@mui/styles';

interface Props {
  couponCode: string;
  status?: OrderStatus;
  couponId?: string;
  handleCouponRemove?: (id: string) => void;
  handleCouponDuplicate?: (code: string) => void;
  loyaltyApplyStatus?: LOYALTY_APPLY_STATUS;
  loyaltyResponseDetail?: string;
  handleLoyaltyRemove?: () => void;
}

const useStyles = makeStyles(({ spacing }: Theme) => ({
  container: {
    marginTop: spacing(2),
    cursor: 'pointer',
  },
  content: {
    paddingBottom: `${spacing(0.5)} !important`,
  },
  codeStyle: {
    marginBottom: `${spacing(0.625)} !important`,
    fontWeight: 'bold !important',
  },
  actionContainer: {
    '& .MuiButtonBase-root.MuiButton-root': {
      textTransform: 'none',
    },
    textAlign: 'right',
  },
  buttonStyle: {
    fontWeight: 'bold !important',
  },
  loyaltyStatusFontStyle: {
    fontSize: `${spacing(1.5)} !important`,
  },
}));

export default function CouponCodeReview({
  couponCode,
  couponId,
  status,
  handleCouponRemove,
  handleCouponDuplicate,
  loyaltyApplyStatus,
  loyaltyResponseDetail,
  handleLoyaltyRemove,
}: Props) {
  const dispatch = useAppDispatch();
  const {
    container,
    content,
    codeStyle,
    buttonStyle,
    actionContainer,
    loyaltyStatusFontStyle,
  } = useStyles();

  const removeCoupon = (e: MouseEvent) => {
    e.stopPropagation();

    if (handleLoyaltyRemove) {
      return handleLoyaltyRemove();
    } else if (handleCouponRemove && couponId) {
      return handleCouponRemove(couponId);
    }
  };

  const showLoyaltyApplyStatus = () => {
    if (loyaltyApplyStatus === LOYALTY_APPLY_STATUS.PROCESSING) {
      return 'Processing...';
    } else {
      if (loyaltyResponseDetail) {
        if (couponCode.length > 6) {
          return 'Invalid phone number';
        }
        return 'Invalid loyalty code';
      } else {
        return 'Invalid coupon';
      }
    }
  };

  useEffect(() => {
    if (status === OrderStatus.completed) {
      // Send Coupon successfully applied metric
      const payload: Partial<InfoTransmissionMessage> = {
        data: {
          message: 'Coupon applied successfully',
          type: 'METRIC',
          code: 'COUPON_APPLIED',
          metadata: { coupon_code: couponCode },
        },
      };
      dispatch(messagingActions.sendInfo(payload as any));
    }
  }, [status]);

  return (
    <Card elevation={3} className={container}>
      <CardContent className={content}>
        <Typography variant="subtitle2" className={codeStyle}>
          {`Coupon #${couponCode}`}
        </Typography>
        {loyaltyApplyStatus &&
          loyaltyApplyStatus !== LOYALTY_APPLY_STATUS.SUCCESS && (
            <Typography variant="subtitle2" className={loyaltyStatusFontStyle}>
              {showLoyaltyApplyStatus()}
            </Typography>
          )}
        <Box className={actionContainer}>
          {handleCouponDuplicate && (
            <Button
              variant="text"
              color="error"
              size="small"
              className={buttonStyle}
              onClick={(e: MouseEvent) => handleCouponDuplicate(couponCode)}
            >
              Duplicate
            </Button>
          )}
          <Button
            variant="text"
            color="error"
            size="small"
            className={buttonStyle}
            onClick={removeCoupon}
          >
            Remove
          </Button>
        </Box>
      </CardContent>
      {loyaltyApplyStatus ? (
        <LoyaltyApplyStatus
          status={loyaltyApplyStatus}
          error={loyaltyResponseDetail}
        />
      ) : (
        <OrderProgressBar status={status} />
      )}
    </Card>
  );
}
